<template>
	<div class="company-dialog">
		<b-modal id="modalExtendedFilter" centered modal-class="modal-warning dialog-1000 custom-dialog"
    @ok="ok" ok-variant="warning" :ok-title="$t('Filtrar')" ok-only
    :title="$t('Búsqueda avanzada')">
			<div class="mt-0">
				<form-render :key="keyFormRender" :fields="fields" :form.sync="form"
          ref="formRenderExtendedFilter"
          containerButtonsClass="col-sm-12 col-lg-4 container-button" @send="onAccept"> 
				</form-render>
			</div>
		</b-modal>
	</div>
</template>
<script>

import { mapGetters } from 'vuex'
import DeliveriesListService from './list-deliveries/deliveriesList.service'

export default {
  props: ['servicesToFilters', 'organizations', 'form'],
  data () {
    return {
      // form: {},
      buttonSend: {text: 'Filtrar', icon: 'SearchIcon', color: 'warning'},
      fields: [],
      keyFormRender: 0,
      loading: {
        carriers: true,
        countries: true,
        shippers: true,
        deliveryStatuses: true,
        deliveryTypes: true,
        total: true
      },
      deliveriesListService: new DeliveriesListService()
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading',
      countries: 'getCountries',
      carriers: 'getCarriers',
      shippers: 'getShippersByOrganization',
      deliveryStatuses: 'getDeliveryStatuses',
      deliveryTypes: 'getDeliveryTypes'
    }),
    permissionList() {
      return this.$store.getters.getRelevantEntities
    }
  },
  mounted () {
    this.setInitialData()
  },
  watch: {
    deliveryStatuses () {
      this.setSelectOptions('order_status_id', { options: this.deliveryStatuses })
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations })
    },
    carriers () {
      this.setSelectOptions('carrier_code', { options: this.carriers.rows })
    },
    shippers () {
      this.setSelectOptions('shipper_id', { options: this.shippers })
    },
    countries () {
      this.setSelectOptions('country_code', { options: this.countries })
    },
    servicesToFilters () {
      if (this.servicesToFilters.delivery_types) {
        const delivery_types = this.servicesToFilters.delivery_types.map(type => ({ ...type, text: type.name}))  
        this.setSelectOptions('order_type', { options: delivery_types })
      }
      if (this.servicesToFilters.statuses) { 
        const statuses = this.servicesToFilters.statuses.map(type => ({ ...type, text: type.name}))  
        this.setSelectOptions('status_id', { options: statuses })
      }
      if (this.servicesToFilters.integrations) { 
        const integrations = this.servicesToFilters.integrations.map(type => ({ ...type, text: type.name}))  
        this.setSelectOptions('integration_id', { options: integrations })
      }
      if (this.servicesToFilters.contracts) { 
        const contracts = this.servicesToFilters.contracts.map(type => ({ ...type, text: type.name}))  
        this.setSelectOptions('contract_type_id', { options: contracts })
      }
      if (this.servicesToFilters.packages) { 
        const packages = this.servicesToFilters.packages.map(type => ({ ...type, text: type.name}))  
        this.setSelectOptions('multi_package', { options: packages })
      }
      // if (this.servicesToFilters.macrostatuses) { 
      //   const macrostatuses = this.servicesToFilters.macrostatuses.map(type => ({ ...type, text: type.name}))  
      //   this.setSelectOptions('order_status_id', { options: macrostatuses })
      // }
    },
    'generalLoading': {
      handler () {
        this.loading.carriers = !!this.generalLoading.getCarriers
        this.loading.countries = !!this.generalLoading.getCountries
        this.loading.shippers = !!this.generalLoading.getShippersByOrganization
        this.loading.deliveryStatuses = !!this.generalLoading.getDeliveryStatuses
        this.loading.deliveryTypes = !!this.generalLoading.getDeliveryTypes
      },
      deep: true
    },
    'loading': {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        // Que la actualización del componente solo se fuerce cuando esté todo cargado
        if (prevTotal !== this.loading.total && !this.loading.total) this.keyFormRender++
      },
      deep: true
    }
  },
  methods: {
    setInitialData () {
      this.fields = [
        {fieldType: 'FieldDatepicker', label: 'Fecha de búsqueda', name: 'find_date', range: true, clearable: true},
        {fieldType: 'FieldInput', label: 'N° de ticket, de envío', name: 'imported_id', containerClass: 'col-md-4 container-info col-sm-12'},
        {fieldType: 'FieldInput', label: 'Orden de flete', name: 'tracking_number', containerClass: 'col-md-4 container-info col-sm-12'},
        {fieldType: 'FieldSelect', clearable: true, label: 'Envío / Devolución', placeholder: 'Seleccione un tipo', name: 'order_type', containerClass: 'col-md-4 container-info col-sm-12'},
        {fieldType: 'FieldSelect', clearable: true, label: 'Courier', name: 'carrier_code', containerClass: 'col-md-4 container-info col-sm-12'},
        {fieldType: 'FieldSelect', clearable: true, label: 'Servicio', name: 'carrier_service', containerClass: 'col-md-4 container-info col-sm-12', dependency: 'carrier_code' },
        {fieldType: 'FieldSelect', clearable: true, label: 'Tipo courier', name: 'carrier_type', containerClass: 'col-md-4 container-info col-sm-12' },
        // {fieldType: 'FieldSelect', clearable: true, label: 'Macroestado', name: 'order_status_id', containerClass: 'col-md-4 container-info col-sm-12'},
        {fieldType: 'FieldSelect', clearable: true, label: 'Estado', name: 'status_id', containerClass: 'col-md-4 container-info col-sm-12'},
        {fieldType: 'FieldSelect', clearable: true, label: 'Tipo de contrato', name: 'contract_type_id', containerClass: 'col-md-4 container-info col-sm-12' },
        {fieldType: 'FieldSelect', clearable: true, label: 'Tipo de bulto', name: 'multi_package', containerClass: 'col-md-4 container-info col-sm-12' },
        {fieldType: 'FieldInput', label: 'Correo', name: 'email', containerClass: 'col-md-4 container-info col-sm-12' },
        {fieldType: 'FieldCheckbox', multiple: true, name: 'show_deleted', containerClass: 'col-md-4 container-info col-sm-12 pt-2', options: [
          {text: 'Mostrar eliminados', id: 1}
        ]}      
      ]
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user')?.role)) {
        this.fields.splice(0, 0, 
          {fieldType: 'FieldSelect',  label: 'Organización', name: 'organization_id', useLabel: true, containerClass: 'col-md-4 container-info col-sm-12', change: this.changeOrganization },
          {fieldType: 'FieldSelect', label: 'Shipper', name: 'shipper_id', useLabel: true, dependency: 'organization_id', containerClass: 'col-md-4 container-info col-sm-12' },
          {fieldType: 'FieldSelect', label: 'País', name: 'country_code',  useLabel: true, containerClass: 'col-md-4 container-info col-sm-12', change: this.changeCountry }
        )
        this.$store.dispatch('fetchService', { name: 'getCountries' })
      } else if (['marketplace'].includes(this.$session.get('cas_user')?.role)) {
        this.fields.splice(0, 0, 
          {fieldType: 'FieldSelect', label: 'Shipper', name: 'shipper_id', useLabel: true, containerClass: 'col-md-4 container-info col-sm-12' }
        )
      }
      if (this.countries && !!this.countries.length) {
        this.setSelectOptions('country_code', { options: this.countries })
      }
    },
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
      // this.form[name] = null
      // this.$emit('update:form', this.form)
    },
    onAccept () {
      this.$emit('result', this.form)
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderExtendedFilter.checkForm()
    },
    changeOrganization (name, value) {
      if (!value || !value.id) return null
      this.form = {
        ...this.form,
        shipper: null
      }
      const index = this.fields.findIndex(el => el.name === 'shipper_id')
      // const queryParams = { paginate_by: 9999, page: 1 }
      const params = { organization_id: value.id }
      this.fields[index].useSkeleton = true
      this.$store.dispatch('fetchService', { name: 'getShippersByOrganization', queryParams: { simplified: true }, params, onSuccess: () => this.fields[index].useSkeleton = false})
    }
  }
}
</script>
<style>

</style>
